import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { registerInstrumentations } from '@opentelemetry/instrumentation'
import { B3Propagator } from '@opentelemetry/propagator-b3'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto'
import { Resource } from '@opentelemetry/resources'
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch'
import config from '@src/config.json'
import traverse from 'traverse'

const addRequestAndResponseBody = (span, request, result) => {
  span.setAttribute('request.body', request.body)
  try {
    const requestParsed = JSON.parse(request.body)

    if (requestParsed?.operationName) {
      if (requestParsed?.variables?.name) {
        span.setAttribute('request.action.name', requestParsed?.variables?.name)
      }
      if (requestParsed?.variables?.params) {
        const parsedParamsBody = JSON.parse(requestParsed?.variables?.params)
        traverse(parsedParamsBody).forEach(function reducer(x) {
          if (this.isLeaf) {
            if (typeof x === 'string') {
              if (
                this.key.match(
                  /(firstname|lastname|email|bankname|accountnumber|routingnumber|accounttype|weight|breed|existingcondition|diagnosis|onsetDate|treatmentstartdate|additionalInsuranceCompany)/i,
                )
              ) {
                this.update('*****')
              }
            }
            if (typeof x === 'number') {
              if (this.key.match(/^age$/i)) {
                this.update('*****')
              }
            }
          }
        })

        span.setAttribute('request.action.variables', JSON.stringify(parsedParamsBody))
      }
    }
  } catch {
    // eslint-disable-next-line no-console
    console.error('Error parsing request body')
  }
  result.text().then((json) => {
    span.setAttribute('response.body', json)
  })
}

const traceExporter = new OTLPTraceExporter({
  headers: {
    'x-honeycomb-team': config.otelWriteKey,
  },
  url: 'https://api.honeycomb.io/v1/traces',
})

const provider = new WebTracerProvider({
  resource: new Resource({
    'service.name': config.otelServiceName,
  }),
  // sampler: process.env.NODE_ENV === 'development'
  //     // disable traces in local
  //     ? new AlwaysOnSampler()
  //     : new TraceIdRatioBasedSampler(0.4)
  // ,
})
provider.addSpanProcessor(new BatchSpanProcessor(traceExporter))
provider.register({
  contextManager: new ZoneContextManager(),
  propagator: new B3Propagator(),
})

registerInstrumentations({
  instrumentations: [
    new FetchInstrumentation({
      applyCustomAttributesOnSpan: addRequestAndResponseBody,
      ignoreNetworkEvents: true,
      ignoreUrls: [/localhost:8000/gi, /cookielaw/gi],
      propagateTraceHeaderCorsUrls: [/.+/g],
    }),
  ],
})
