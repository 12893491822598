import Box from '@basecomponents/Box'
import axios from 'axios'
import jstz from 'jstimezonedetect'
import Moment from 'moment'
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import config from '@src/config.json'
import { Resource } from '@opentelemetry/resources'
import { BatchSpanProcessor, WebTracerProvider } from '@opentelemetry/sdk-trace-web'
import { ZoneContextManager } from '@opentelemetry/context-zone'
import { B3Propagator } from '@opentelemetry/propagator-b3'
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-proto'
import Modal from '@basecomponents/Modal'

const traceExporter = new OTLPTraceExporter({
  headers: {
    'x-honeycomb-team': config.otelWriteKey,
  },
  url: 'https://api.honeycomb.io/v1/traces',
})

const provider = new WebTracerProvider({
  resource: new Resource({
    'service.name': config.otelServiceName,
  }),
})
provider.addSpanProcessor(new BatchSpanProcessor(traceExporter))
provider.register({
  contextManager: new ZoneContextManager(),
  propagator: new B3Propagator(),
})

const Tracer = provider.getTracer('timezone')

/**
 * HOC for notifying about time drift
 * @category BaseComponents
 * @param {React.ReactNode} children
 * @param {string} variation
 * @returns {React.FC}
 */
const SystemTimeCheck = () => {
  const [time, setTime] = useState('')
  const [absDifference, setAbsDifference] = useState(-1)
  const [difference, setDifference] = useState('')
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const timezone = jstz.determine()
  // https://bugs.chromium.org/p/chromium/issues/detail?id=580195
  const name = timezone.name().replace('Calcutta', 'Kolkata')

  const absoluteDifferenceRef = useRef()
  const networkTime = useRef()

  useEffect(() => {
    const fetchTime = async () => {
      const sysTime = Moment.now()
      setLoading(true)
      try {
        const baseRequestString = 'https://timeapi.io/api/time/current/zone'
        const requestString = `${baseRequestString}?timeZone=${name}`
        const response = await axios.get(requestString)
        const time = await response.data.dateTime
        setTime(time)
        const currentTime = Moment(time)
        networkTime.current = currentTime.toISOString()
        const timeDiff = currentTime.diff(sysTime)
        const diffDuration = Moment.duration(timeDiff).humanize()
        setDifference(diffDuration)
        const absoluteDiff = Math.abs(currentTime.diff(sysTime))
        absoluteDifferenceRef.current = absoluteDiff
        setAbsDifference(absoluteDiff)
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            setShowDialog(true)
          }, 2000)
        } else {
          setShowDialog(false)
        }
      } catch (err) {
        try {
          const baseRequestString = 'https://worldtimeapi.org/api/timezone'
          const requestString = `${baseRequestString}/${name}`
          const response = await axios.get(requestString)
          const time = await response.data.datetime
          setTime(time)
          const currentTime = Moment(time)
          networkTime.current = currentTime.toISOString()
          const timeDiff = currentTime.diff(sysTime)
          const diffDuration = Moment.duration(timeDiff).humanize()
          setDifference(diffDuration)
          const absoluteDiff = Math.abs(currentTime.diff(sysTime))
          absoluteDifferenceRef.current = absoluteDiff
          setAbsDifference(absoluteDiff)
          if (document.visibilityState === 'visible') {
            setTimeout(() => {
              setShowDialog(true)
            }, 2000)
          } else {
            setShowDialog(false)
          }
        } catch (err) {
          noop()
        }
      } finally {
        setLoading(false)
        if (absoluteDifferenceRef.current > 300000) {
          const dateString = new Date().toISOString()
          Tracer.startActiveSpan('Custom span', (span) => {
            span.setAttribute('custom.timezone', name)
            span.setAttribute('custom.differenceHumanized', Moment.duration(absoluteDifferenceRef.current).humanize())
            span.setAttribute('custom.difference', absoluteDifferenceRef.current)
            span.setAttribute('custom.networkTime', networkTime.current)
            span.setAttribute('custom.systemTime', dateString)
            span.setAttribute('custom.userAgent', window?.navigator?.userAgent)
            setTimeout(() => span.end(), 100)
          })
        }
      }
    }
    const interval = setTimeout(
      () => {
        fetchTime()
      },
      absDifference === -1 ? 10000 : 300000,
    )
    return () => {
      clearInterval(interval)
    }
  }, [absDifference])

  return (
    <>
      {!loading && absDifference > 300000 && showDialog && (
        <Modal
          footerSx={{
            p: 0,
          }}
          isOpen={absDifference > 300000 && showDialog && !loading}
        >
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box as="h2" sx={{ color: 'error' }}>
              Error!
            </Box>
            <Box
              as="h4"
              sx={{
                mt: 3,
                textAlign: 'justify',
              }}
            >
              Unfortunately, the system timed out! If this continues to occur, please check that your computer clock is
              displaying the correct time.
            </Box>
            <Box
              as="h4"
              sx={{
                mt: 1,
                textAlign: 'justify',
              }}
            >
              {`This system's clock has a difference of ${difference} from the correct time in ${name}. The correct time is: `}
            </Box>
            <Box as="h1" sx={{ mt: 3 }}>
              {Moment(time).format('hh:mm:ss A')}
            </Box>
            <Box
              as="h4"
              sx={{
                mt: 3,
                textAlign: 'justify',
              }}
            >
              Please correct your system clock. If you are still experiencing issues, please contact the IAIC
              administrator, 844-738-4242 or IAIC.pets@independenceamerican.com
            </Box>
            <Box
              as="h4"
              sx={{
                color: 'error',
                mt: 1,
                textAlign: 'justify',
              }}
            >
              Once you have corrected your system&apos;s clock, please refresh the page.
            </Box>
          </Box>
        </Modal>
      )}
    </>
  )
}

export default SystemTimeCheck

SystemTimeCheck.propTypes = {
  children: PropTypes.shape({}).isRequired,
}
