import { createContext } from 'react'

/**
 * Permission control
 * @category MISC
 * @type {Object}
 */
const EditAccessRightsContext = createContext()

export default EditAccessRightsContext
