// import '@src/components/wdyr'
import App from '@basecomponents/App'
import { Auth } from 'aws-amplify'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import SnackbarProvider from 'react-simple-snackbar'
import { get } from 'lodash'
import { createGlobalStyle } from 'styled-components'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga4'
import { lighten } from 'polished'
import Box from '@basecomponents/Box'
import { BarLoader } from 'react-spinners'
import { useTranslation } from 'react-i18next'
import config from '@src/config.json'
import theme from '@src/theme'
import EditAccessRightsProvider from '@petcomponents/EditAccessRights/Provider'
import Modal from '@basecomponents/Modal'
import SystemTimeCheck from '@basecomponents/SystemTimeCheck'
import '@utils/tracing'
import '@utils/i18n'

ReactGA.initialize(config.gaTag)

const GlobalStyle = createGlobalStyle`
  * {
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${(p) => lighten(0.1, `${p.theme.colors.accentLight}`)};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${(p) => p.theme.colors.accent};
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
`

const isBrowser = typeof window !== 'undefined'
const height = isBrowser && window.innerHeight
const width = isBrowser && window.innerWidth

const PetAppLayout = ({ children }) => {
  const { t } = useTranslation()
  const isResponsive = height < 1100 && width < 1100
  const [defaultTheme, setDefaultTheme] = useState(null)
  const [mga, setMga] = useState(null)
  useEffect(() => {
    const getTheme = async () => {
      const userInfo = await Auth.currentUserInfo()
      const userMga = config.canadaEnv
        ? get(userInfo, 'attributes.custom:mga', 'ptz_sodaco')
        : get(userInfo, 'attributes.custom:mga', 'default')
      setDefaultTheme(userMga)
      setMga(userMga)
    }
    getTheme()
  })

  const [isHighContrastOn, setIsHighContrastOn] = useState(false)
  const [showContrastResetModal, setShowContrastResetModal] = useState(false)

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isHighContrastOn,
        isResponsive,
        setShowContrastResetModal,
      })
    }
    return child
  })

  if (!defaultTheme || !mga) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="10rem"
        justifyContent="center"
        sx={{
          m: '0 !important',
        }}
      >
        <BarLoader color="black" />
      </Box>
    )
  }

  return (
    <App
      config={config}
      credentials={isBrowser && Auth.currentCredentials}
      theme={theme(defaultTheme, isHighContrastOn)}
    >
      <GlobalStyle />
      <Helmet
        htmlAttributes={{
          lang: 'en-us',
        }}
      >
        <meta content="width=device-width, initial-scale=1.0, viewport-fit=cover" name="viewport" />
        <link href={`/${config.favicon[mga.toLowerCase()]}.ico`} rel="icon" type="image/png" />
      </Helmet>
      <Modal
        closeText={t('common.no')}
        isOpen={showContrastResetModal}
        onClose={() => setShowContrastResetModal(false)}
        onSubmit={() => {
          setShowContrastResetModal(false)
          setIsHighContrastOn(!isHighContrastOn)
        }}
        submitText={t('common.yes')}
        title="Caution!"
      >
        <Box>All unsaved changes will be lost. Do you wish to continue?</Box>
      </Modal>
      <SystemTimeCheck />
      <EditAccessRightsProvider>
        <SnackbarProvider>{childrenWithProps}</SnackbarProvider>
      </EditAccessRightsProvider>
    </App>
  )
}

PetAppLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
}

export default PetAppLayout
