import PropTypes from 'prop-types'
import React from 'react'
import Box from '@basecomponents/Box'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo })
  }

  render() {
    const { children } = this.props
    const { error, errorInfo } = this.state

    if (errorInfo) {
      return (
        <Box p={6}>
          <h1>Something went wrong.</h1>
          <details>
            <Box as="pre" color="error" pt={5}>
              {error && error.toString()}
              {errorInfo.componentStack}
            </Box>
          </details>
        </Box>
      )
    }

    return children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ErrorBoundary
