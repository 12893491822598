// NOTE: this file is generated automatically, any changes will be overwritten.
import Box from '@basecomponents/Box'
import PropTypes from 'prop-types'
import React from 'react'
import accidentillness from '../../images/icons/accident-illness.svg'
import accidentonly from '../../images/icons/accident-only.svg'
import account from '../../images/icons/account.svg'
import addtolist from '../../images/icons/add-to-list.svg'
import add from '../../images/icons/add.svg'
import alternateemail from '../../images/icons/alternate-email.svg'
import appstore from '../../images/icons/appstore.svg'
import aspcamono from '../../images/icons/aspca-mono.svg'
import aspca from '../../images/icons/aspca.svg'
import assigned from '../../images/icons/assigned.svg'
import avatar from '../../images/icons/avatar.svg'
import back from '../../images/icons/back.svg'
import bankdetails from '../../images/icons/bank-details.svg'
import billing from '../../images/icons/billing.svg'
import bin from '../../images/icons/bin.svg'
import calendar from '../../images/icons/calendar.svg'
import chatbubble from '../../images/icons/chatbubble.svg'
import checkwhite from '../../images/icons/check-white.svg'
import check from '../../images/icons/check.svg'
import checkbox from '../../images/icons/checkbox.svg'
import checkin from '../../images/icons/checkin.svg'
import claims from '../../images/icons/claims.svg'
import clock from '../../images/icons/clock.svg'
import closemodal from '../../images/icons/close-modal.svg'
import close from '../../images/icons/close.svg'
import closecircle from '../../images/icons/closecircle.svg'
import closeerror from '../../images/icons/closeerror.svg'
import cnfmono from '../../images/icons/cnf-mono.svg'
import cnf from '../../images/icons/cnf.svg'
import communicationpreference from '../../images/icons/communication-preference.svg'
import contrast from '../../images/icons/contrast.svg'
import creategroup from '../../images/icons/create-group.svg'
import dashboard from '../../images/icons/dashboard.svg'
import description from '../../images/icons/description.svg'
import details from '../../images/icons/details.svg'
import documentnotification from '../../images/icons/document-notification.svg'
import documentpanel from '../../images/icons/document-panel.svg'
import document from '../../images/icons/document.svg'
import dollar from '../../images/icons/dollar.svg'
import download from '../../images/icons/download.svg'
import dropdown from '../../images/icons/dropdown.svg'
import editlocation from '../../images/icons/edit-location.svg'
import edit from '../../images/icons/edit.svg'
import error from '../../images/icons/error.svg'
import expandless from '../../images/icons/expand-less.svg'
import expandmore from '../../images/icons/expand-more.svg'
import feedback from '../../images/icons/feedback.svg'
import figomono from '../../images/icons/figo-mono.svg'
import figo from '../../images/icons/figo.svg'
import filedescription from '../../images/icons/file-description.svg'
import file from '../../images/icons/file.svg'
import filterempty from '../../images/icons/filter-empty.svg'
import filterfilled from '../../images/icons/filter-filled.svg'
import fineosmono from '../../images/icons/fineos-mono.svg'
import fineos from '../../images/icons/fineos.svg'
import groups from '../../images/icons/groups.svg'
import helpcenter from '../../images/icons/helpcenter.svg'
import history from '../../images/icons/history.svg'
import home from '../../images/icons/home.svg'
import iaicmono from '../../images/icons/iaic-mono.svg'
import iaic from '../../images/icons/iaic.svg'
import idammono from '../../images/icons/idam-mono.svg'
import idam from '../../images/icons/idam.svg'
import info from '../../images/icons/info.svg'
import jobprotectedleave from '../../images/icons/job-protected-leave.svg'
import kebabmenu from '../../images/icons/kebab-menu.svg'
import kwikcoverlogowhite from '../../images/icons/kwikcover-logo-white.svg'
import kwikcoverlogo from '../../images/icons/kwikcover-logo.svg'
import leavestatusclosed from '../../images/icons/leave-status-closed.svg'
import leavestatusdecided from '../../images/icons/leave-status-decided.svg'
import leavestatuspending from '../../images/icons/leave-status-pending.svg'
import leftchevron from '../../images/icons/left-chevron.svg'
import list from '../../images/icons/list.svg'
import logout from '../../images/icons/logout.svg'
import mail from '../../images/icons/mail.svg'
import manage from '../../images/icons/manage.svg'
import menubar from '../../images/icons/menubar.svg'
import messagenotification from '../../images/icons/message-notification.svg'
import message from '../../images/icons/message.svg'
import minus from '../../images/icons/minus.svg'
import movie from '../../images/icons/movie.svg'
import notes from '../../images/icons/notes.svg'
import notifications from '../../images/icons/notifications.svg'
import onepacken from '../../images/icons/onepack-en.svg'
import onepackmono from '../../images/icons/onepack-mono.svg'
import onepack from '../../images/icons/onepack.svg'
import payments from '../../images/icons/payments.svg'
import pbmono from '../../images/icons/pb-mono.svg'
import pb from '../../images/icons/pb.svg'
import pending from '../../images/icons/pending.svg'
import personaldetail from '../../images/icons/personal-detail.svg'
import petpaw from '../../images/icons/pet-paw.svg'
import petcomono from '../../images/icons/petco-mono.svg'
import petco from '../../images/icons/petco.svg'
import phone from '../../images/icons/phone.svg'
import pill from '../../images/icons/pill.svg'
import playstore from '../../images/icons/playstore.svg'
import plus from '../../images/icons/plus.svg'
import ppimono from '../../images/icons/ppi-mono.svg'
import ppi from '../../images/icons/ppi.svg'
import primaryaddress from '../../images/icons/primary-address.svg'
import producer from '../../images/icons/producer.svg'
import promote from '../../images/icons/promote.svg'
import questioncircle from '../../images/icons/question-circle.svg'
import quote from '../../images/icons/quote.svg'
import radiobuttonchecked from '../../images/icons/radio-button-checked.svg'
import radiobuttonunchecked from '../../images/icons/radio-button-unchecked.svg'
import refresh from '../../images/icons/refresh.svg'
import remove from '../../images/icons/remove.svg'
import renewal from '../../images/icons/renewal.svg'
import reports from '../../images/icons/reports.svg'
import rightchevron from '../../images/icons/right-chevron.svg'
import rowheightlg from '../../images/icons/row-height-lg.svg'
import rowheightmd from '../../images/icons/row-height-md.svg'
import rowheightsm from '../../images/icons/row-height-sm.svg'
import search from '../../images/icons/search.svg'
import settings from '../../images/icons/settings.svg'
import sftp from '../../images/icons/sftp.svg'
import spraoilogo from '../../images/icons/spraoi-logo.svg'
import statusleaveclosed from '../../images/icons/status-leave-closed.svg'
import statusleavedecided from '../../images/icons/status-leave-decided.svg'
import statusleavepending from '../../images/icons/status-leave-pending.svg'
import submitted from '../../images/icons/submitted.svg'
import subscriptions from '../../images/icons/subscriptions.svg'
import tasksnotification from '../../images/icons/tasks-notification.svg'
import tasks from '../../images/icons/tasks.svg'
import tickgreen from '../../images/icons/tick-green.svg'
import tickthin from '../../images/icons/tick-thin.svg'
import tickwrapup from '../../images/icons/tick-wrap-up.svg'
import timeline from '../../images/icons/timeline.svg'
import trashrestore from '../../images/icons/trash-restore.svg'
import trash from '../../images/icons/trash.svg'
import unassigned from '../../images/icons/unassigned.svg'
import uncheck from '../../images/icons/uncheck.svg'
import upload from '../../images/icons/upload.svg'
import userfilled from '../../images/icons/user-filled.svg'
import usersadd from '../../images/icons/users-add.svg'
import visibility from '../../images/icons/visibility.svg'
import visibilityoff from '../../images/icons/visibilityoff.svg'
import workplaceaccomodation from '../../images/icons/work-place-accomodation.svg'
import work from '../../images/icons/work.svg'
const svgMap = {
  'accident-illness': accidentillness,
  'accident-only': accidentonly,
  account: account,
  'add-to-list': addtolist,
  add: add,
  'alternate-email': alternateemail,
  appstore: appstore,
  'aspca-mono': aspcamono,
  aspca: aspca,
  assigned: assigned,
  avatar: avatar,
  back: back,
  'bank-details': bankdetails,
  billing: billing,
  bin: bin,
  calendar: calendar,
  chatbubble: chatbubble,
  'check-white': checkwhite,
  check: check,
  checkbox: checkbox,
  checkin: checkin,
  claims: claims,
  clock: clock,
  'close-modal': closemodal,
  close: close,
  closecircle: closecircle,
  closeerror: closeerror,
  'cnf-mono': cnfmono,
  cnf: cnf,
  'communication-preference': communicationpreference,
  contrast: contrast,
  'create-group': creategroup,
  dashboard: dashboard,
  description: description,
  details: details,
  'document-notification': documentnotification,
  'document-panel': documentpanel,
  document: document,
  dollar: dollar,
  download: download,
  dropdown: dropdown,
  'edit-location': editlocation,
  edit: edit,
  error: error,
  'expand-less': expandless,
  'expand-more': expandmore,
  feedback: feedback,
  'figo-mono': figomono,
  figo: figo,
  'file-description': filedescription,
  file: file,
  'filter-empty': filterempty,
  'filter-filled': filterfilled,
  'fineos-mono': fineosmono,
  fineos: fineos,
  groups: groups,
  helpcenter: helpcenter,
  history: history,
  home: home,
  'iaic-mono': iaicmono,
  iaic: iaic,
  'idam-mono': idammono,
  idam: idam,
  info: info,
  'job-protected-leave': jobprotectedleave,
  'kebab-menu': kebabmenu,
  'kwikcover-logo-white': kwikcoverlogowhite,
  'kwikcover-logo': kwikcoverlogo,
  'leave-status-closed': leavestatusclosed,
  'leave-status-decided': leavestatusdecided,
  'leave-status-pending': leavestatuspending,
  'left-chevron': leftchevron,
  list: list,
  logout: logout,
  mail: mail,
  manage: manage,
  menubar: menubar,
  'message-notification': messagenotification,
  message: message,
  minus: minus,
  movie: movie,
  notes: notes,
  notifications: notifications,
  'onepack-en': onepacken,
  'onepack-mono': onepackmono,
  onepack: onepack,
  payments: payments,
  'pb-mono': pbmono,
  pb: pb,
  pending: pending,
  'personal-detail': personaldetail,
  'pet-paw': petpaw,
  'petco-mono': petcomono,
  petco: petco,
  phone: phone,
  pill: pill,
  playstore: playstore,
  plus: plus,
  'ppi-mono': ppimono,
  ppi: ppi,
  'primary-address': primaryaddress,
  producer: producer,
  promote: promote,
  'question-circle': questioncircle,
  quote: quote,
  'radio-button-checked': radiobuttonchecked,
  'radio-button-unchecked': radiobuttonunchecked,
  refresh: refresh,
  remove: remove,
  renewal: renewal,
  reports: reports,
  'right-chevron': rightchevron,
  'row-height-lg': rowheightlg,
  'row-height-md': rowheightmd,
  'row-height-sm': rowheightsm,
  search: search,
  settings: settings,
  sftp: sftp,
  'spraoi-logo': spraoilogo,
  'status-leave-closed': statusleaveclosed,
  'status-leave-decided': statusleavedecided,
  'status-leave-pending': statusleavepending,
  submitted: submitted,
  subscriptions: subscriptions,
  'tasks-notification': tasksnotification,
  tasks: tasks,
  'tick-green': tickgreen,
  'tick-thin': tickthin,
  'tick-wrap-up': tickwrapup,
  timeline: timeline,
  'trash-restore': trashrestore,
  trash: trash,
  unassigned: unassigned,
  uncheck: uncheck,
  upload: upload,
  'user-filled': userfilled,
  'users-add': usersadd,
  visibility: visibility,
  visibilityoff: visibilityoff,
  'work-place-accomodation': workplaceaccomodation,
  work: work,
}
const Icon = ({ svg, ...rest }) => {
  const SvgComponent = svgMap[svg]
  return (
    <Box {...rest}>
      <SvgComponent />
    </Box>
  )
}
Icon.propTypes = {
  svg: PropTypes.oneOf([
    'accident-illness',
    'accident-only',
    'account',
    'add-to-list',
    'add',
    'alternate-email',
    'appstore',
    'aspca-mono',
    'aspca',
    'assigned',
    'avatar',
    'back',
    'bank-details',
    'billing',
    'bin',
    'calendar',
    'chatbubble',
    'check-white',
    'check',
    'checkbox',
    'checkin',
    'claims',
    'clock',
    'close-modal',
    'close',
    'closecircle',
    'closeerror',
    'cnf-mono',
    'cnf',
    'communication-preference',
    'contrast',
    'create-group',
    'dashboard',
    'description',
    'details',
    'document-notification',
    'document-panel',
    'document',
    'dollar',
    'download',
    'dropdown',
    'edit-location',
    'edit',
    'error',
    'expand-less',
    'expand-more',
    'feedback',
    'figo-mono',
    'figo',
    'file-description',
    'file',
    'filter-empty',
    'filter-filled',
    'fineos-mono',
    'fineos',
    'groups',
    'helpcenter',
    'history',
    'home',
    'iaic-mono',
    'iaic',
    'idam-mono',
    'idam',
    'info',
    'job-protected-leave',
    'kebab-menu',
    'kwikcover-logo-white',
    'kwikcover-logo',
    'leave-status-closed',
    'leave-status-decided',
    'leave-status-pending',
    'left-chevron',
    'list',
    'logout',
    'mail',
    'manage',
    'menubar',
    'message-notification',
    'message',
    'minus',
    'movie',
    'notes',
    'notifications',
    'onepack-en',
    'onepack-mono',
    'onepack',
    'payments',
    'pb-mono',
    'pb',
    'pending',
    'personal-detail',
    'pet-paw',
    'petco-mono',
    'petco',
    'phone',
    'pill',
    'playstore',
    'plus',
    'ppi-mono',
    'ppi',
    'primary-address',
    'producer',
    'promote',
    'question-circle',
    'quote',
    'radio-button-checked',
    'radio-button-unchecked',
    'refresh',
    'remove',
    'renewal',
    'reports',
    'right-chevron',
    'row-height-lg',
    'row-height-md',
    'row-height-sm',
    'search',
    'settings',
    'sftp',
    'spraoi-logo',
    'status-leave-closed',
    'status-leave-decided',
    'status-leave-pending',
    'submitted',
    'subscriptions',
    'tasks-notification',
    'tasks',
    'tick-green',
    'tick-thin',
    'tick-wrap-up',
    'timeline',
    'trash-restore',
    'trash',
    'unassigned',
    'uncheck',
    'upload',
    'user-filled',
    'users-add',
    'visibility',
    'visibilityoff',
    'work-place-accomodation',
    'work',
  ]).isRequired,
}
export default Icon
